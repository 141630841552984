import {
  BooleanField,
  BooleanInput,
  BulkDeleteButton,
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  Resource,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { CrudPermissions } from "../auth/crud-premissions";
import GoogleIcon from "@mui/icons-material/Google";
import { AuConversionEventTypeInput } from "./au-conversion-event-type-input";
import { TopHelpInfoText } from "../help/top-help-info-text";
import { GoogleAdsClickConversionConfigResource } from "@arrowsup/platform-dtos";
import { omit } from "lodash";

export const GoogleAdsClickConversionResource = (
  crudPermissions: CrudPermissions
) => {
  return (
    <Resource
      name="google-ads-click-conversion-config"
      key="google-ads-click-conversion-config"
      options={{
        label: "Google Ads Click Conversions",
      }}
      icon={GoogleIcon}
      list={GoogleAdsClickConversionList(crudPermissions)}
      create={
        crudPermissions.create ? GoogleAdsClickConversionCreate : undefined
      }
      edit={crudPermissions.update ? GoogleAdsClickConversionEdit : undefined}
    />
  );
};

const GoogleAdsClickConversionInput: React.FC = () => {
  return (
    <TextInput
      source="googleAdsConversionActionId"
      label="Google Ads Conversion Action ID"
      helperText="The unique ID of the Google Ads conversion action."
      fullWidth={true}
      sx={{ pb: 2 }}
    />
  );
};

const EnhancedInput: React.FC = () => (
  <BooleanInput
    source="enhanced"
    label="Enhanced"
    helperText="If we should send enhanced conversion information to Google (email & phone).  This requires the account to be set up for enhanced conversions."
    sx={{ pb: 2 }}
  />
);

const EnabledInput: React.FC = () => (
  <BooleanInput
    source="enabled"
    label="Enabled"
    helperText="We'll only send these conversions to Google if enabled."
    sx={{ pb: 2 }}
  />
);

const NotesInput: React.FC = () => (
  <TextInput
    source="notes"
    label="Notes"
    helperText="Notes about this conversion, for posterity."
    fullWidth={true}
    sx={{ pb: 2 }}
  />
);

const GoogleAdsClickConversionEdit: React.FC = () => {
  return (
    <Edit
      redirect="list"
      transform={
        (d: GoogleAdsClickConversionConfigResource) =>
          omit(d, ["createdAt", "updatedAt", "org"]) // Don't send these fields to the server.
      }
    >
      <SimpleForm>
        <GoogleAdsClickConversionInput />
        <AuConversionEventTypeInput />
        <EnhancedInput />
        <EnabledInput />
        <NotesInput />
      </SimpleForm>
    </Edit>
  );
};

const GoogleAdsClickConversionList = (crudPermissions: CrudPermissions) => {
  return (
    <div>
      <TopHelpInfoText text="Send AU conversions to Google Ads for reporting and optimization." />
      <List>
        <Datagrid
          rowClick={crudPermissions.update ? "edit" : false}
          bulkActionButtons={
            crudPermissions.delete ? <BulkDeleteButton /> : false
          }
        >
          <TextField
            source="googleAdsConversionActionId"
            label="Google Ads Conversion Action ID"
          />
          <TextField
            source="auConversionEventType"
            label="AU Conversion Event Type"
          />
          <BooleanField source="enhanced" label="Enhanced" />
          <BooleanField source="enabled" label="Enabled" />
          <TextField source="notes" label="Notes" />
          <DateField source="createdAt" label="Created" />
          <DateField source="updatedAt" label="Updated" />
        </Datagrid>
      </List>
    </div>
  );
};

const GoogleAdsClickConversionCreate: React.FC = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <GoogleAdsClickConversionInput />
        <AuConversionEventTypeInput />
        <EnhancedInput />
        <EnabledInput />
        <NotesInput />
      </SimpleForm>
    </Create>
  );
};
