import { Card, CardContent } from "@mui/material";
import { Title } from "react-admin";

interface Props {
  title: string;
}

/**
 * A component to remove some of the boilerplate when dealing
 * with ReactAdmin Routes
 */
export const RouteElement: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  children,
}) => {
  return (
    <Card>
      <Title title={title} />
      <CardContent>{children}</CardContent>
    </Card>
  );
};
