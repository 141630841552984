import React from "react";
import { CircularProgress } from "@mui/material";
import { Center } from "../util/center";

export const Loading: React.FC = () => {
  return (
    <Center>
      <CircularProgress data-testid="loading" />
    </Center>
  );
};
