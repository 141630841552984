export const ArrowsUpWhiteLogo: React.FC = () => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 3148.2 557.65"
  >
    <polygon
      fill="#fff"
      points="3148.2 0 3136.99 159.37 2999.64 84.23 3148.2 0"
    />
    <path
      fill="#fff"
      d="M2575.05,521.77c-.44.21-72.4,35.39-72.4,35.39l.46-.75,202.9-291.69s.22-.12.32-.06l163.9,98.72c.11.07.33-.07.33-.07l185.18-268.9.3-.09,50.01,21.72.11.37-218.77,316.99s-.22.14-.33.07l-168-102.89c-.11-.07-.33.06-.33.06,0,0-36.27,46.81-52.94,69.34-30.5,41.22-90.75,121.79-90.75,121.79"
    />
    <path
      fill="#fff"
      d="M2086.51,423.2c-37.77,0-66.58-12.63-86.43-37.89-19.85-25.26-29.77-62.21-29.77-110.85l.74-228.11h70.27l.69,212.68c0,30.4,5.17,52.92,15.53,67.53,10.35,14.62,25.98,21.92,46.89,21.92,22.83,0,39.8-7.31,50.9-21.92,11.09-14.61,16.65-36.66,16.65-66.13l-2.08-214.08h73.26l1.5,378.24h-74.75l2.08-43.49c-10.03,14.03-22.14,24.56-36.33,31.57-14.2,7.02-30.57,10.52-49.14,10.52h0Z"
    />
    <path
      fill="#fff"
      d="M2308.54,557.2l.39-510.86h70.27l1.04,37.63c19.85-24.08,45.14-36.13,75.87-36.13,27.95,0,52.02,7.6,72.18,22.8,20.17,15.21,35.74,36.83,46.74,64.9,10.99,28.06,16.48,61.39,16.48,99.98s-5.66,70.86-16.97,98.92c-11.31,28.06-27.1,49.87-47.38,65.42-20.28,15.55-44.07,23.33-71.38,23.33-16.01,0-30.25-3.1-42.73-9.3-12.48-6.19-23.42-15.61-32.81-28.24v135.41l-71.7,36.13h0ZM2445.22,348.47c23.69,0,41.88-9.82,54.58-29.47,12.69-19.65,19.05-47.47,19.05-83.49s-6.51-63.32-19.53-83.31c-13.02-20-31.05-29.99-54.1-29.99-14.08,0-26.57,3.16-37.45,9.47-10.88,6.31-20.06,15.44-27.53,27.36v151.9c7.47,11.93,16.86,21.17,28.17,27.71,11.31,6.55,23.58,9.82,36.81,9.82h0Z"
    />
    <g>
      <path
        fill="#fff"
        d="M121.83,424.59c-38.37,0-68.26-10.33-89.69-30.98S0,344.33,0,307.72c0-23.46,5.29-43.7,15.88-60.72,10.59-17.01,25.66-30.15,45.22-39.43,19.55-9.27,42.79-13.9,69.69-13.9,18.68,0,37.37,2.82,56.05,8.45s37.37,14.32,56.05,26.05v-53.51c0-35.2-7.6-61.25-22.79-78.15-15.2-16.9-38.74-25.35-70.63-25.35-17.19,0-35.82,2.99-55.87,8.98-20.06,5.98-39.8,14.49-59.23,25.52l-8.22-21.83c21.67-11.73,43.16-20.65,64.46-26.75s41.67-9.15,61.1-9.15c37.87,0,67.01,10.03,87.44,30.1,20.42,20.07,30.64,48.76,30.64,86.07l-.1,260.5h-28.41l1.6-64.77c-15.2,20.42-33.57,36.32-55.12,47.7s-43.53,17.07-65.96,17.07h.03ZM121.83,401.36c22.17,0,43.35-5.98,63.53-17.95,20.18-11.97,39.36-29.69,57.55-53.16v-78.15c-18.19-11.27-36.81-19.94-55.87-26.05-19.06-6.1-37.81-9.15-56.24-9.15-30.15,0-54.62,8.33-73.43,24.99-18.81,16.67-28.21,38.61-28.21,65.83s8.47,51.1,25.41,68.12,39.36,25.52,67.26,25.52h0Z"
      />
      <path
        fill="#fff"
        d="M368.38,424.59l1.5-378.24h25.42l.82,86.77c10.96-26.75,26.22-47.64,45.78-62.66s41.67-22.53,66.33-22.53c22.67,0,39.61,4.93,50.82,14.79l-4.48,25.35c-6.23-3.99-13.77-6.98-22.61-8.98-8.85-1.99-18-2.99-27.47-2.99-21.18,0-39.93,5.98-56.24,17.95-16.32,11.97-29.09,28.57-38.3,49.81-9.22,21.24-13.83,45.59-13.83,73.04l.68,207.69h-28.42Z"
      />
      <path
        fill="#fff"
        d="M603.1,424.59l-1.5-378.24h28.41l.02,86.77c10.96-26.75,26.22-47.64,45.78-62.66s41.67-22.53,66.33-22.53c22.67,0,39.61,4.93,50.82,14.79l-4.48,25.35c-6.23-3.99-13.77-6.98-22.61-8.98-8.85-1.99-18-2.99-27.47-2.99-21.18,0-39.93,5.98-56.24,17.95-16.32,11.97-29.09,28.57-38.3,49.81-9.22,21.24-13.83,45.59-13.83,73.04l-.02,207.69h-26.91Z"
      />
      <path
        fill="#fff"
        d="M946.92,424.59c-46.59,0-83.4-16.84-110.43-50.52-27.03-33.67-40.55-79.5-40.55-137.46,0-38.49,6.17-71.87,18.5-100.15s29.77-50.1,52.32-65.48c22.55-15.37,49.26-23.06,80.16-23.06s57.98,7.57,80.53,22.71,39.92,36.79,52.13,64.95c12.2,28.16,18.31,61.6,18.31,100.33s-6.17,71.87-18.5,100.15c-12.33,28.28-29.77,50.11-52.32,65.48-22.55,15.37-49.27,23.06-80.16,23.06h.01ZM946.92,399.24c38.61,0,68.57-14.31,89.87-42.95,21.3-28.63,31.95-68.76,31.95-120.39s-10.59-92.23-31.76-120.39c-21.18-28.16-51.19-42.24-90.06-42.24s-69.82,14.03-90.62,42.07c-20.8,28.05-31.2,68.47-31.2,121.27s10.59,92.23,31.76,120.39c21.17,28.16,51.2,42.24,90.06,42.24Z"
      />
      <path
        fill="#fff"
        d="M1226.53,424.59l-95.68-378.24h25.42l76.9,287.42c1.5,5.63,2.99,11.44,4.49,17.43,1.5,5.98,2.99,12.14,4.48,18.48,1.99-6.34,3.92-12.5,5.79-18.48s3.67-11.79,5.42-17.43l91.29-287.42h17.94l92.56,287.42c1.99,5.63,3.92,11.44,5.79,17.43,1.87,5.99,3.67,12.14,5.42,18.48,1.5-6.34,2.99-12.5,4.49-18.48,1.49-5.98,2.99-11.79,4.48-17.43l72.64-287.42h28.41l-97.18,378.24h-20.93l-88.32-273.17c-2.49-7.74-5.04-15.66-7.66-23.76-2.61-8.1-5.3-16.37-8.03-24.82-2.49,8.45-5.04,16.72-7.66,24.82-2.61,8.1-5.3,16.02-8.03,23.76l-89.59,273.17h-22.44Z"
      />
      <path
        fill="#fff"
        d="M1736.15,424.59c-22.42,0-43.97-4.64-64.65-13.9-20.68-9.27-39.24-22.71-55.68-40.31l20.93-16.9c14.7,15.02,30.64,26.64,47.83,34.85,17.19,8.22,34.13,12.32,50.82,12.32,28.15,0,49.76-5.98,64.84-17.95,15.07-11.97,22.61-28.98,22.61-51.04,0-13.14-3.61-25.22-10.84-36.26-7.23-11.03-18.56-21.24-34-30.62-15.45-9.38-35.38-18.31-59.79-26.75-36.87-12.9-63.4-27.22-79.6-42.95-16.2-15.72-24.29-35.2-24.29-58.43,0-26.52,10.28-47.93,30.83-64.24,20.55-16.31,47.65-24.47,81.28-24.47,20.67,0,40.73,3.87,60.16,11.62,19.43,7.74,36,18.66,49.7,32.74l-20.18,16.19c-27.41-24.41-57.55-36.61-90.43-36.61-25.66,0-46.03,5.75-61.1,17.25-15.08,11.5-22.61,27.34-22.61,47.52,0,17.6,6.6,32.33,19.8,44.18,13.2,11.85,37.74,24.11,73.62,36.79,29.15,10.33,52.13,20.89,68.95,31.68,16.82,10.8,28.71,22.83,35.69,36.08,6.97,13.26,10.46,28.69,10.46,46.29,0,28.87-10.15,51.57-30.46,68.12s-48.27,24.82-83.89,24.82v-.02Z"
      />
    </g>
    <line fill="#fff" x1="2308.93" y1="557.65" x2="2380.69" y2="521.77" />
    <line fill="#fff" x1="2380.69" y1="521.77" x2="2308.93" y2="557.65" />
  </svg>
);
