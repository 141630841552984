import { Typography } from "@mui/material";
import {
  BulkDeleteButton,
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  Resource,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { CrudPermissions } from "../auth/crud-premissions";
import { FilterAlt } from "@mui/icons-material";
import { TopHelpInfoText } from "../help/top-help-info-text";
import { omit } from "lodash";
import { WebViewFilterResource as WebViewFilterResourceDTO } from "@arrowsup/platform-dtos";

export const WebViewFilterResource = (perms: CrudPermissions) => (
  <Resource
    name="web-view-filter"
    key="web-view-filter"
    options={{
      label: "Web View Filters",
    }}
    list={WebViewFilterList(perms.update, perms.delete)}
    edit={perms.update ? WebViewFilterEdit : undefined}
    create={perms.create ? WebViewFilterCreate : undefined}
    icon={FilterAlt}
  />
);

export const WebViewFilterList = (
  updateAllowed: boolean,
  deleteAllowed: boolean
) => (
  <div>
    <TopHelpInfoText
      text="
       Filter rules exclude certain users or addresses from the charts
        and dashboards. E.g. employees or offices."
    />

    <List>
      <Datagrid
        rowClick={updateAllowed ? "show" : false}
        bulkActionButtons={deleteAllowed ? <BulkDeleteButton /> : false}
      >
        <TextField source="userId" label="User ID" />
        <TextField source="clientAddress" label="Client Address" />
        <TextField source="notes" label="Notes" />
        <DateField source="createdAt" label="Created" />
        <DateField source="updatedAt" label="Updated" />
      </Datagrid>
    </List>
  </div>
);

export const WebViewFilterEdit = () => (
  <div>
    <TopHelpInfoText
      text="It may take up to 24 hours for charts and dashboards to be updated with
        any changes."
    />
    <Edit
      redirect="list"
      transform={(d: WebViewFilterResourceDTO) => {
        return omit(d, ["createdAt", "updatedAt"]);
      }}
    >
      <SimpleForm>
        <TextInput
          source="userId"
          label="User ID"
          helperText="Filter this unique User ID from all charts and dashboards."
          fullWidth
        />
        <Typography
          variant="subtitle2"
          align="center"
          sx={{ p: "1rem", width: "100%" }}
        >
          and/or
        </Typography>
        <TextInput
          source="clientAddress"
          label="Address"
          fullWidth
          helperText="Filter this user's (IP) address from all charts and dashboards.
        Any User IDs seen at this address will also be filtered (even from other addresses).
        This is a good way to filter out everyone from a shared office, for example."
          sx={{ pb: "3rem" }}
        />
        <TextInput
          source="notes"
          label="Notes"
          fullWidth
          multiline
          helperText="Your notes about who/where this is."
        />
      </SimpleForm>
    </Edit>
  </div>
);

export const WebViewFilterCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <TextInput source="userId" label="User ID" />
      <Typography variant="body1" sx={{ pb: "1rem" }}>
        or
      </Typography>
      <TextInput source="clientAddress" label="Client Address" />
      <TextInput source="notes" label="Notes" fullWidth multiline />
    </SimpleForm>
  </Create>
);
