import React from "react";
import {
  BulkDeleteButton,
  Create,
  Datagrid,
  Edit,
  List,
  Resource,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { CrudPermissions } from "../auth/crud-premissions";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import { GoodDataDashboardShow } from "./gooddata-dashboard-show";

export const GoodDataDashboardResource = (perms: CrudPermissions) => {
  return (
    <Resource
      name="dashboard"
      key={"dashboard"}
      options={{
        label: "Dashboards",
      }}
      list={GoodDataDashboardList(perms)}
      create={perms.create ? GoodDataDashboardCreate : undefined}
      edit={perms.update ? GoodDataDashboardEdit : undefined}
      show={GoodDataDashboardShow}
      icon={AnalyticsIcon}
    />
  );
};

const GoodDataDashboardList = (perms: CrudPermissions) => (
  <div>
    <List exporter={false}>
      <Datagrid
        rowClick={perms.read ? "show" : false}
        bulkActionButtons={perms.delete ? <BulkDeleteButton /> : false}
      >
        <TextField source="title" />
      </Datagrid>
    </List>
  </div>
);

const GoodDataDashboardEdit = () => (
  <Edit redirect="list">
    <SimpleForm>
      <TextField source="title" />
      <TextField source="dashboardId" />
    </SimpleForm>
  </Edit>
);

const GoodDataDashboardCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <TextInput
        source="title"
        label="Title"
        helperText="Title of this dashboard when displayed in the list of dashboards."
        sx={{ pb: "1rem" }}
        fullWidth
      />
      <TextInput
        source="dashboardId"
        label="Dashboard ID"
        helperText="ID of this Dashboard in GoodData"
        fullWidth
      />
    </SimpleForm>
  </Create>
);
