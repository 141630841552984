import { AxiosInstance } from "axios";
/**
 * Creates a JWT for accessing GoodData.UI - so we can show dashboards.
 */
export const createGoodDataJwt = (
  api: AxiosInstance
): (() => Promise<string>) => {
  return async () => {
    return api
      .post<string>("/api/integration/gooddata/jwt")
      .then((res) => res.data);
  };
};
