import React, { useState, useEffect } from "react";
import {
  OauthInFlightError,
  OauthService,
} from "../../../logic/integrations/oauth/oauth.service";
import { Loading } from "../../feedback/loading";
import { Link } from "react-router-dom";

interface Props {
  oauthService: OauthService;
}

/**
 * Because ReactAdmin signs all of its requests with JWTs, and JWTs are not
 * stored by the browser (like cookies are), the oauth provider cannot redirect
 * the oauth requests directly to the backend because the oauth provider does not
 * have access to the JWT (through the browser). Therefore, we had to create this
 * redirect page whose sole responsibility is to take the redirect request
 * from the provider, and then pass it to the backend after attaching the JWT.
 * At which point the backend will store the oauth tokens for the organization
 * associated for the user that is encoded in the JWT.
 */
export const OauthRedirect = ({ oauthService }: Props) => {
  const [status, setStatus] = useState<string>("Not Set");

  // when this page is hit, read in the parameters attached to the url by the
  // oauth provider, and forward them to the backend
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const code = queryParameters.get("code");
    const state = queryParameters.get("state");
    const realmId = queryParameters.get("realmId");

    async function oauthCallbackFn() {
      if (code) {
        await oauthService.callbackFn(code, state, realmId).then(
          (stuff) => {
            setStatus(stuff);
          },
          (err) => {
            if (err instanceof OauthInFlightError) {
              console.warn(`${err.message}`);
            } else {
              throw err;
            }
          }
        );
      }
    }

    if (status === "Not Set") {
      void oauthCallbackFn();
    }
  }, [oauthService, status]);

  // based on the status, display a spinner, a link back to the integrations
  // page, or an error.
  if (status === "Not Set") {
    return (
      <div>
        <Loading />
      </div>
    );
  } else if (status === "Success") {
    return (
      <div>
        Successfully connected. Return to{" "}
        <Link to="/integrations">Integrations</Link>
      </div>
    );
  } else {
    return <div>Error connecting: {status}</div>;
  }
};
