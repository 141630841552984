import { OauthService } from "../../../../logic/integrations/oauth/oauth.service";
import { OauthConfig } from "../common/OauthConfig";

interface Props {
  zohoOauthService: OauthService;
}

/**
 * This is the specific Zoho instantion of the OauthConfig
 */
export const ZohoOauthConfig = ({ zohoOauthService }: Props) => {
  return (
    <OauthConfig
      name="Zoho"
      description="Connect the platform to your Zoho account."
      oauthService={zohoOauthService}
    />
  );
};
