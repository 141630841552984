import React, { PropsWithChildren, useContext } from "react";
import { UserContext } from "../../logic/auth/user-context";

export const SuperUserOnly: React.FC<PropsWithChildren> = ({ children }) => {
  const user = useContext(UserContext).user;
  if (user?.superUser) {
    return <>{children}</>;
  } else {
    return <></>;
  }
};
