import { Button } from "@mui/material";
import { OauthService } from "../../../../logic/integrations/oauth/oauth.service";

interface Props {
  oauthService: OauthService;
  onRevokeComplete: () => void;
}

/**
 * The button used to revoke oauth integration with a provider.
 * Is where the revoke service call actually gets  made.
 */
export const RevokeIntegrationButton = ({
  oauthService,
  onRevokeComplete,
}: Props) => {
  return (
    <Button
      style={{ width: "250px", marginTop: "1rem" }}
      variant="contained"
      color="error"
      onClick={() => void oauthService.revoke().then(onRevokeComplete)}
    >
      Revoke Access
    </Button>
  );
};
