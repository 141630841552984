import { AuthProvider, UserIdentity } from "react-admin";
import { useAuth0 } from "@auth0/auth0-react";
import * as ss from "superstruct";
import { useContext } from "react";
import { UserContext } from "./user-context";

export const useAuAuthProvider = (): AuthProvider => {
  const auth0 = useAuth0();
  const userContext = useContext(UserContext);
  const realUser = userContext.user;
  const activeUser = userContext.impersonatedUser ?? realUser;

  return {
    login: () => {
      // No-op for us - the entire app is wrapped in an auth0 provider, which
      // requires login.
      return Promise.resolve();
    },
    // called when the user clicks on the logout button
    logout: () => {
      if (auth0.isAuthenticated) {
        return Promise.resolve(
          auth0.logout({
            returnTo: window.location.origin,
          })
        );
      } else {
        return Promise.resolve();
      }
    },
    // called when the API returns an error
    checkError: ({ status }) => {
      if (status === 401 || status === 403) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
      // No-op for us - entire app is wrapped in auth0 provider, which requires
      // login.
      return Promise.resolve();
    },
    getPermissions: () => {
      ss.assert(activeUser, ss.type({ policies: ss.array(ss.string()) }));
      return Promise.resolve(activeUser.policies);
    },
    getIdentity: () => {
      // Always want to show who we're logged in as, not who we're impersonating.
      const identity: UserIdentity = {
        id: realUser.email,
        avatar: auth0?.user?.picture,
        fullName: realUser.name,
      };
      return Promise.resolve(identity);
    },
  };
};
