import simpleRestProvider from "ra-data-simple-rest";
import { DataProvider, fetchUtils } from "react-admin";
import { PlatformUiEnvToHostMap } from "./platform-ui-env-to-host-map";
import { useContext } from "react";
import { AccessTokenContext } from "../auth/access-token-context";
import { usePlatformUiEnv } from "../env/use-platform-ui-env";
import { PlatformUiEnv } from "../platform-ui-envs.type";
import {
  AuImpersonateOrgHeader,
  AuImpersonateUserHeader,
  OrgDto,
  UserDto,
} from "@arrowsup/platform-dtos";
import { UserContext } from "../auth/user-context";

export const AuDataProviderPathPrefix = `/api/data`;

export const makeAuDataProvider = (
  env: PlatformUiEnv,
  accessToken: string,
  impersonateUser?: UserDto,
  impersonateOrg?: OrgDto
): DataProvider => {
  const fetchJson: typeof fetchUtils.fetchJson = (url, options = {}) => {
    const headers = options.headers
      ? new Headers(options.headers)
      : new Headers();

    headers.set("Authorization", "Bearer " + accessToken);

    // Add impersonation, if applicable.  The backend will
    // validate the access token is of a superuser, otherwise,
    // this will be rejected.
    if (impersonateUser) {
      headers.set(AuImpersonateUserHeader, impersonateUser.id.toString());
    }

    if (impersonateOrg) {
      headers.set(AuImpersonateOrgHeader, impersonateOrg.id.toString());
    }

    options.headers = headers;
    return fetchUtils.fetchJson(url, options);
  };

  return simpleRestProvider(
    PlatformUiEnvToHostMap[env] + AuDataProviderPathPrefix,
    fetchJson
  );
};

export const useAuDataProvider = (): DataProvider => {
  const accessToken = useContext(AccessTokenContext).accessToken;
  const env = usePlatformUiEnv();
  const userContext = useContext(UserContext);
  return makeAuDataProvider(
    env,
    accessToken,
    userContext.impersonatedUser,
    userContext.impersonatedOrg
  );
};
