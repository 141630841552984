import { InfoOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { mutedTextColor } from "../../admin/theme";

export const TopHelpInfoText: React.FC<PropsWithChildren<{ text: string }>> = ({
  text,
}) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    paddingTop="2rem"
    color={mutedTextColor}
  >
    <InfoOutlined sx={{ mr: "1rem", height: "100%" }} />
    <Typography variant="subtitle1" align="center">
      {text}
    </Typography>
  </Box>
);
