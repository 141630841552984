import { Box, Grid } from "@mui/material";
import React, { PropsWithChildren } from "react";

export const Center: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Box display="flex" style={{ width: "100%", height: "100%" }}>
      <Grid container spacing={0} justifyContent="center" direction="column">
        <Grid item>
          <Box display="flex" justifyContent="center">
            {children}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
