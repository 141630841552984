import { OauthProvider, OauthStatusDto } from "@arrowsup/platform-dtos";
import { AxiosInstance } from "axios";

/**
 * In order to prevent sending the same request multiple times, we track
 * which (callbackFn) requests are currently in flight, and if the current one
 * is already in flight, we throw this error.
 */
export class OauthInFlightError extends Error {
  constructor(provider: OauthProvider, code: string) {
    super(`oauth callback already in flight for ${provider}, code: ${code}`);
  }
}

/**
 * This is the service that communicates with the oauth controller in
 * platform. Completely circumvents the dataprovider.
 */
export class OauthService {
  constructor(
    private readonly provider: OauthProvider,
    private readonly api: AxiosInstance
  ) {}

  /**
   * To prevent double callbacks due to react-rendering, we track
   * in flight callbacks.
   */
  private inFlightCodes: Set<string> = new Set();

  /**
   * When connecting to the external oauth service, what is the url in their
   * system we need to hit to connect AU to the external system.
   * @returns The external URL
   */
  readonly generateAuthUrl = async (): Promise<string> => {
    const resp = await this.api.get<string>(
      `/api/integrations/${this.provider}/oauth/generate`
    );
    return resp.data;
  };

  /**
   * What is the satus of the oauth connection
   * @returns The oauth connection status
   */
  readonly status = async (): Promise<OauthStatusDto> => {
    const resp = await this.api.get<OauthStatusDto>(
      `/api/integrations/${this.provider}/oauth/status`
    );
    return resp.data;
  };

  /**
   * Revoke the oauth connection
   * @returns void
   */
  readonly revoke = async (): Promise<void> => {
    await this.api.post(`/api/integrations/${this.provider}/oauth/revoke`);
  };

  /**
   * ReactAdmin relies on JWTs for authenication. Which means if the
   * oauth provider redirects directly to a backend service, the JWT won't be
   * connected, beacuse the JWT gets added through the application itself, and not the
   * browser, as in the case with cookies.
   * Thus, we have to accepted the callback from the oauth provider
   * in our front end, parse the parameters, and then pass it through to the
   * backend after connecting the JWT. This is the function the front end calls
   * after parsing the parameters from the oauth provider.
   * @param code the oauth code
   * @param state the oauth state (only used for intuit)
   * @param realmId the realmId (only used for intuit)
   * @returns the status of the service call. 'Success' if successful.
   */
  readonly callbackFn = async (
    code: string,
    state: string | null,
    realmId: string | null
  ): Promise<string> => {
    if (this.inFlightCodes.has(code)) {
      throw new OauthInFlightError(this.provider, code);
    }

    let stateSegment = "";
    if (state) {
      stateSegment = `&state=${state}`;
    }

    let realmIdSegment = "";
    if (realmId) {
      realmIdSegment = `&realmId=${realmId}`;
    }

    this.inFlightCodes.add(code);
    try {
      const resp = await this.api.get<string>(
        `/api/integrations/${this.provider}/oauth/callback?code=${code}${stateSegment}${realmIdSegment}`
      );
      return resp.data;
    } finally {
      this.inFlightCodes.delete(code);
    }
  };
}
