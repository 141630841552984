import { GoogleAdsClickConversionSpecialEventTypes } from "@arrowsup/platform-dtos";
import { FormControlLabel, Grid, Switch, TextField } from "@mui/material";
import { useController } from "react-hook-form";

export const AuConversionEventTypeInput: React.FC = () => {
  const input = useController({
    name: "auConversionEventType",
  });
  const allLeads =
    "_ALL_LEADS" satisfies GoogleAdsClickConversionSpecialEventTypes;
  const clickToCall =
    "_CLICK_TO_CALL" satisfies GoogleAdsClickConversionSpecialEventTypes;
  const isAllLeads = input.field.value === allLeads;
  const isClickToCall = input.field.value === clickToCall;
  const isSpecial = isAllLeads || isClickToCall;

  return (
    <Grid container width="100%" pb={2}>
      <Grid item xs={8}>
        <TextField
          size="small"
          variant="outlined"
          fullWidth={true}
          {...input.field}
          disabled={isSpecial}
          label={isSpecial ? "" : "Arrows Up Conversion Event"}
          helperText="The Exact Arrows Up Conversion Event to send to Google Ads, or all lead / all click-to-call conversions if the appropriate slider is toggled on."
        />
      </Grid>
      <Grid item xs={4} textAlign="center" pl={2}>
        <FormControlLabel
          control={
            <Switch
              checked={isAllLeads}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                  input.field.onChange(allLeads);
                } else {
                  input.field.onChange("");
                }
              }}
            />
          }
          label="All Leads"
        />
        <FormControlLabel
          control={
            <Switch
              checked={isClickToCall}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                  input.field.onChange(clickToCall);
                } else {
                  input.field.onChange("");
                }
              }}
            />
          }
          label="Click to Call"
        />
      </Grid>
    </Grid>
  );
};
