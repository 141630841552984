import { OauthConfig } from "../common/OauthConfig";
import { OauthService } from "../../../../logic/integrations/oauth/oauth.service";

interface Props {
  vcrmOauthService: OauthService;
}

/**
 * This is the specific Vcrm instantiation of the OauthConfig
 */
export const VcrmOauthConfig = ({ vcrmOauthService }: Props) => {
  return (
    <OauthConfig
      name="CRM"
      description="Connect your CRM account to the rest of the platform."
      oauthService={vcrmOauthService}
    />
  );
};
