import {
  BulkDeleteButton,
  Create,
  Datagrid,
  Edit,
  List,
  Resource,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { CrudPermissions } from "../auth/crud-premissions";
import { TopHelpInfoText } from "../help/top-help-info-text";
import React from "react";
import FlightLandIcon from "@mui/icons-material/FlightLand";

export const LandingPageResource = (perms: CrudPermissions) => (
  <Resource
    name="landing-page"
    key="landing-page"
    options={{
      label: "Landing Pages",
    }}
    icon={FlightLandIcon}
    list={LandingPageResourceList(perms)}
    edit={perms.update ? LandingPageResourceEdit : undefined}
    create={perms.create ? LandingPageResourceCreate : undefined}
  />
);

export const LandingPageResourceList = (perms: CrudPermissions) => {
  return (
    <div>
      <TopHelpInfoText text="Configure deployed landing pages on your website.  When ads are configured to point to a (unsearchable) landing page, we can track the ad's performance with better accuracy." />
      <List>
        <Datagrid
          rowClick={perms.update ? "show" : false}
          bulkActionButtons={perms.delete ? <BulkDeleteButton /> : false}
        >
          <TextField source="url" label="URL" />
          <TextField source="adSource" label="Ad Source" />
          <TextField source="campaignId" label="Campaign ID" />
          <TextField source="adGroupId" label="Ad Group ID" />
          <TextField source="adId" label="Ad ID" />
          <TextField source="phoneNormalized" label="Phone (E164)" />
        </Datagrid>
      </List>
    </div>
  );
};

const UrlInput: React.FC = () => {
  return (
    <TextInput
      source="url"
      label="URL"
      helperText="Full URL of this landing page, no trailing '/'.  E.g. https://www.example.com/lp/offer1"
      fullWidth
      required
      sx={{ pb: 2 }}
    />
  );
};

const AdSourceInput: React.FC = () => {
  return (
    <SelectInput
      source="adSource"
      label="Ad Source"
      helperText="Which Ad network is pointing to this LP?"
      choices={["google", "meta"].map((v) => ({
        id: v,
        name: v,
      }))}
      fullWidth
      required
      sx={{ pb: 2 }}
    />
  );
};

export const CampaignIdInput: React.FC = () => {
  return (
    <TextInput
      source="campaignId"
      label="Campaign ID"
      helperText="Optional campaign ID associated with LP."
      fullWidth
      sx={{ pb: 2 }}
    />
  );
};

export const AdGroupId: React.FC = () => (
  <TextInput
    source="adGroupId"
    label="Ad Group ID"
    helperText="Optional Ad Group ID associated with LP."
    fullWidth
    sx={{ pb: 2 }}
  />
);

export const AdId: React.FC = () => (
  <TextInput
    source="adId"
    label="Ad ID"
    helperText="Optional Ad ID associated with LP."
    fullWidth
    sx={{ pb: 2 }}
  />
);

export const PhoneInput: React.FC = () => (
  <TextInput
    source="phoneNormalized"
    label="Phone (E164)"
    helperText="If there is call forwarding set up for this LP, this is the tracking number."
    fullWidth
    sx={{ pb: 2 }}
  />
);

export const LandingPageResourceEdit: React.FC = () => {
  return (
    <div>
      <Edit redirect="list">
        <SimpleForm>
          <UrlInput />
          <AdSourceInput />
          <CampaignIdInput />
          <AdGroupId />
          <AdId />
          <PhoneInput />
        </SimpleForm>
      </Edit>
    </div>
  );
};

export const LandingPageResourceCreate: React.FC = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <UrlInput />
        <AdSourceInput />
        <CampaignIdInput />
        <AdGroupId />
        <AdId />
        <PhoneInput />
      </SimpleForm>
    </Create>
  );
};
