import { Chip } from "@mui/material";
import { Link, ErrorOutline } from "@mui/icons-material";
import { red, green, grey } from "@mui/material/colors";

interface Props {
  connected: boolean;
}

/**
 * A simple indicator of whether or not an OAuth provider is connected
 */
export const IntegrationConnectedIndicator = (props: Props) => {
  const { connected } = props;
  const text = connected ? " Connected " : " Not Connected ";
  const icon = connected ? (
    <Link style={{ color: green[600] }} />
  ) : (
    <ErrorOutline style={{ color: red[500] }} />
  );

  return (
    <Chip
      icon={icon}
      label={text}
      variant="outlined"
      sx={{
        borderColor: connected ? green[600] : red[500],
        fontWeight: 600,
        color: grey[800],
      }}
    />
  );
};
