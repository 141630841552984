import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthConfigService } from "../../logic/auth/auth-config.service";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { Loading } from "../feedback/loading";
import { Alert, Typography } from "@mui/material";
import { AccessTokenContext } from "../../logic/auth/access-token-context";
import { FullHeightContainer } from "../util/full-height-container";
import { BaseLoginOptions } from "@auth0/auth0-spa-js";
import { PlatformUiEnv } from "../../logic/platform-ui-envs.type";
import { UserContext } from "../../logic/auth/user-context";
import { OrgDto, UserDto } from "@arrowsup/platform-dtos";
import { PlatformUiEnvToHostMap } from "../../logic/data-provider/platform-ui-env-to-host-map";
import { usePlatformUiEnv } from "../../logic/env/use-platform-ui-env";
import { fetchUtils } from "react-admin";

interface Props {
  authConfigService: AuthConfigService;
}

const FullHeightLoading: React.FC = () => (
  <FullHeightContainer>
    <Loading />
  </FullHeightContainer>
);

export const platformUiAudienceMap: Record<PlatformUiEnv, string> = {
  dev: `https://goarrowsup.auth0.com/api/v2/`,
  "platform-prod": "https://goarrowsup.auth0.com/api/v2/",
} as const;

const getLoginOpts = (audience: string): BaseLoginOptions => {
  return {
    audience,
  };
};

/** Return the actual user, not impersonation - only used in setting up auth. */
const getRealUser = async (
  env: PlatformUiEnv,
  accessToken: string
): Promise<UserDto> => {
  const resp = await fetchUtils.fetchJson(
    PlatformUiEnvToHostMap[env] + "/api/users/self",
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  );
  return resp.json as Promise<UserDto>;
};

const AuthRequiredUserContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [userDto, setUserDto] = useState<undefined | UserDto>(undefined);
  const [impersonatedUser, setImpersonatedUser] = React.useState<
    UserDto | undefined
  >(undefined);
  const [impersonatedOrg, setImpersonatedOrg] = React.useState<
    OrgDto | undefined
  >(undefined);
  const [loadErr, setLoadErr] = useState<unknown | undefined>(undefined);

  const env = usePlatformUiEnv();
  const accessToken = useContext(AccessTokenContext).accessToken;

  useEffect(() => {
    getRealUser(env, accessToken)
      .then(setUserDto)
      .catch((e) => setLoadErr(e));
  }, [accessToken, env]);

  if (loadErr) {
    return (
      <FullHeightContainer>
        <Alert severity="error">
          Unable to login: {String(loadErr)}
          <a
            href={
              "https://goarrowsup.auth0.com/v2/logout?returnTo=" +
              encodeURIComponent(
                window.location.protocol + "//" + window.location.host
              )
            }
            style={{ paddingLeft: "1em" }}
          >
            Logout
          </a>
        </Alert>
      </FullHeightContainer>
    );
  }

  if (userDto === undefined) {
    return <FullHeightLoading />;
  }

  return (
    <UserContext.Provider
      value={{
        user: userDto,
        impersonatedOrg,
        impersonatedUser,
        setImpersonatedOrg: (org: OrgDto) => {
          setImpersonatedOrg(org);
          setImpersonatedUser(undefined);
        },
        setImpersonatedUser,
      }}
      // Force redraw w/ new key.
      key={`${userDto.id}-${impersonatedOrg?.id ?? "undefined"}-${
        impersonatedUser?.id ?? "undefined"
      }`}
    >
      {children}
    </UserContext.Provider>
  );
};

const AuthRequiredAccessTokenProvider: React.FC<{
  children: React.ReactNode;
  audience: string;
}> = ({ children, audience }) => {
  const auth0 = useAuth0();
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [error, setError] = useState<unknown | undefined>(undefined);

  useEffect(() => {
    auth0
      .getAccessTokenSilently(getLoginOpts(audience))
      .then(setAccessToken)
      .catch(setError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0.getAccessTokenSilently]);

  if (error) {
    const errStr = error instanceof Error ? error.message : String(error);
    return (
      <Alert severity="error">Unable to get user credentials: {errStr}</Alert>
    );
  } else if (accessToken === undefined) {
    return <FullHeightLoading />;
  } else {
    return (
      <AccessTokenContext.Provider value={{ accessToken }}>
        <AuthRequiredUserContextProvider>
          {children}
        </AuthRequiredUserContextProvider>
      </AccessTokenContext.Provider>
    );
  }
};

const AuthRequiredChildWrapper: React.FC<{
  children: React.ReactNode;
  audience: string;
}> = ({ children, audience }) => {
  const auth0 = useAuth0();

  if (auth0.isLoading) {
    return <FullHeightLoading />;
  } else if (auth0.isAuthenticated) {
    return (
      <AuthRequiredAccessTokenProvider audience={audience}>
        {children}
      </AuthRequiredAccessTokenProvider>
    );
  } else {
    if (window.location.href.includes("oauth")) {
      void auth0.loginWithPopup(getLoginOpts(audience)); // Redirects.
    } else {
      void auth0.loginWithRedirect(getLoginOpts(audience));
    }
    return <Typography variant="body1">Redirecting to login</Typography>;
  }
};

/**
 * Requires authentication to render children and provides
 * children with UserContext and AccessTokenContext.
 *
 * Displays a loading spinner while loading.  Automatically
 * redirects to login if not authenticated.
 */
export const AuthRequired: React.FC<React.PropsWithChildren<Props>> = ({
  authConfigService,
  children,
}) => {
  const config = authConfigService.config();
  const audience: string = platformUiAudienceMap[config.env];

  return (
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
      redirectUri={window.location.origin}
      audience={audience}
    >
      <AuthRequiredChildWrapper audience={audience}>
        {children}
      </AuthRequiredChildWrapper>
    </Auth0Provider>
  );
};
