import { OauthService } from "../../../../logic/integrations/oauth/oauth.service";
import { OauthConfig } from "../common/OauthConfig";

interface Props {
  intuitOauthService: OauthService;
}

/**
 * This is the specific Intuit instantiation of the OauthConfig
 */
export const IntuitOauthConfig = ({ intuitOauthService }: Props) => {
  return (
    <OauthConfig
      name="Intuit"
      description="Connect your Intuit account to the rest of the platform."
      oauthService={intuitOauthService}
    />
  );
};
