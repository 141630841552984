import { AxiosInstance } from "axios";

/**
 * Sets up this user's GoodData user with the correct permissions to view this org's
 * dashboards.
 */
export const selfProvisionGoodDataUser = (api: AxiosInstance) => {
  return async () => {
    return api.post("/api/integration/gooddata/self-provision");
  };
};
