import { Button } from "@mui/material";
import { OauthService } from "../../../../logic/integrations/oauth/oauth.service";

interface Props {
  // the oauth service, used to get the authUrl redirect
  oauthService: OauthService;
}

/**
 * If an oauth provider isn't connected, this is the button that gets
 * displayed. When clicked, it makes the call to generate the authUrl,
 * and then navigates to that url. The authUrl will be in the auth
 * provider's system itself (external to AU).
 */
export const AddIntegrationButton = ({ oauthService }: Props) => {
  return (
    <Button
      variant="contained"
      color="primary"
      style={{
        marginTop: "1rem",
      }}
      onClick={() => {
        const asyncFn = async () => {
          const authUrl = await oauthService.generateAuthUrl();
          window.location.href = authUrl;
        };

        void asyncFn();
      }}
    >
      Connect
    </Button>
  );
};
