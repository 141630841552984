import { RaThemeOptions, defaultTheme } from "react-admin";

export const auTheme = {
  ...defaultTheme,
  palette: {
    primary: {
      main: "#f09000",
      light: "#ECB76A",
      contrastText: "#fff",
    },
    secondary: {
      main: "#4A72A4",
      dark: "#113050",
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: 15, // Should be a number in pixels
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 700,
    fontWeightBold: 800,
  },
  components: {
    // Sidebar menu items
    MuiMenuItem: {
      styleOverrides: {
        root: {
          // Increase font weight for selected menu item
          "&.RaMenuItemLink-active": {
            fontWeight: 600,
          },
          // Targets the icon of the active menu item
          "&.RaMenuItemLink-active svg": {
            color: "black",
          },
        },
      },
    },
  },
} as const satisfies RaThemeOptions;

// This should, probably, be folded into the theme object above.
export const mutedTextColor = "#00000099";
