import { OrgDto, UserDto, fakeUserDto } from "@arrowsup/platform-dtos";
import React from "react";

export interface UserContextValue {
  user: UserDto;
  impersonatedOrg?: OrgDto;
  impersonatedUser?: UserDto;
  setImpersonatedOrg: (org: OrgDto) => void;
  setImpersonatedUser: (user: UserDto) => void;
}

export const UserContext = React.createContext<UserContextValue>({
  user: fakeUserDto(), // Annoying, but makes types cleaner downstream.
  impersonatedOrg: undefined,
  impersonatedUser: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setImpersonatedOrg: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setImpersonatedUser: () => {},
});
