import axios, { AxiosInstance, AxiosError } from "axios";
import { PlatformUiEnvToHostMap } from "../data-provider/platform-ui-env-to-host-map";
import { usePlatformUiEnv } from "../env/use-platform-ui-env";
import { AccessTokenContext } from "../auth/access-token-context";
import { useContext, useMemo } from "react";
import { PlatformUiEnv } from "../platform-ui-envs.type";
import { UserContext } from "../auth/user-context";
import {
  AuImpersonateOrgHeader,
  AuImpersonateUserHeader,
  OrgDto,
  UserDto,
} from "@arrowsup/platform-dtos";

export const makeApi = (
  env: PlatformUiEnv,
  accessToken: string,
  impersonatedUser?: UserDto,
  impersonatedOrg?: OrgDto
): AxiosInstance => {
  const headers: Record<string, string | number> = {
    Authorization: `Bearer ${accessToken}`,
  };

  // Add impersonation, if applicable.  The backend will
  // validate the access token is of a superuser, otherwise,
  // this will be rejected.
  if (impersonatedUser) {
    headers[AuImpersonateUserHeader] = impersonatedUser.id;
  }

  if (impersonatedOrg) {
    headers[AuImpersonateOrgHeader] = impersonatedOrg.id;
  }

  const thing = axios.create({
    baseURL: PlatformUiEnvToHostMap[env],
    headers,
  });
  thing.interceptors.response.use(
    (resp) => {
      return resp;
    },
    (error: AxiosError) => {
      alert(error.response?.data ?? "Request failed");
      return Promise.reject(error);
    }
  );
  return thing;
};

/**
 * Create an Axios instance to communicate with the backend.
 * This goes entirely around the DataProvider, and lets us hit any backend
 * endpoint.
 *
 * This respects any impersonated users
 *
 * @returns the axios instance
 */
export const useApi: () => AxiosInstance = () => {
  const env = usePlatformUiEnv();
  const accessToken = useContext(AccessTokenContext).accessToken;
  const userContext = useContext(UserContext);
  return useMemo(() => {
    return makeApi(
      env,
      accessToken,
      userContext.impersonatedUser,
      userContext.impersonatedOrg
    );
  }, [
    env,
    accessToken,
    userContext.impersonatedUser,
    userContext.impersonatedOrg,
  ]);
};
