import { OauthService } from "../../../../logic/integrations/oauth/oauth.service";
import { OauthConfig } from "../common/OauthConfig";

interface Props {
  oauthService: OauthService;
}

export const GoogleOauthConfig = ({ oauthService }: Props) => {
  return (
    <OauthConfig
      name="Google"
      description="Connect your Google account to the rest of the platform."
      oauthService={oauthService}
    />
  );
};
