import { useState, useEffect } from "react";
import { IntegrationConnectedIndicator } from "./IntegrationConnectedIndicator";
import { OauthStatusDto } from "@arrowsup/platform-dtos";
import { OauthService } from "../../../../logic/integrations/oauth/oauth.service";
import { RevokeIntegrationButton } from "./RevokeIntegrationButton";
import { Box, Card, CardContent, CardHeader } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Loading } from "../../../feedback/loading";
import { AddIntegrationButton } from "./AddIntegrationButton";
import { grey } from "@mui/material/colors";

interface Props {
  name: string;
  description: string;
  oauthService: OauthService; // created in admin/index.tsx
}

/**
 * An abstracted component for connecting/revoking oauth connections for a
 * single oauth provider.
 *
 * If the provider is connected, display the 'revoke' button. If the provider
 * isn't connected, display the 'connect' button.
 *
 * This component gets the connection status based upon a call to the backend.
 */
export const OauthConfig = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState<OauthStatusDto>({ connected: false });

  useEffect(() => {
    void props.oauthService.status().then((status) => {
      setStatus(status);
      setIsLoading(false);
    });
  }, [isLoading, props.oauthService]);

  return (
    <Card sx={{ mb: 2, boxShadow: "none", border: `1px solid ${grey[300]}` }}>
      <CardHeader
        title={props.name}
        sx={{
          ".MuiCardHeader-title": {
            fontWeight: 600,
            fontSize: "2rem",
            color: grey[900],
          },
        }}
        action={
          !isLoading ? (
            <span className="ml-auto">
              <IntegrationConnectedIndicator connected={status.connected} />
            </span>
          ) : (
            <span />
          )
        }
      />
      <CardContent>
        <Grid container>
          <Grid container item justifyContent="center" spacing={2}>
            <Box sx={{ marginLeft: "1rem" }}>
              <p>{props.description}</p>
            </Box>
          </Grid>
          <Grid container item justifyContent="center" spacing={2}>
            <div className="d-flex justify-content-center">
              {isLoading ? (
                <Loading />
              ) : status.connected ? (
                <RevokeIntegrationButton
                  oauthService={props.oauthService}
                  onRevokeComplete={() => setIsLoading(true)}
                />
              ) : (
                <AddIntegrationButton oauthService={props.oauthService} />
              )}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
