import { SvgIcon } from "@mui/material";
import * as React from "react";

export default function ConversionIcon(
  props: React.ComponentProps<typeof SvgIcon>
) {
  return (
    <SvgIcon
      {...props}
      width="1200pt"
      height="1200pt"
      version="1.1"
      viewBox="0 0 1200 1200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m88.5 304.12v-88.375c0.03125-20.543 8.207-40.238 22.734-54.766s34.223-22.703 54.766-22.734h714.25c20.543 0.03125 40.238 8.207 54.766 22.734s22.703 34.223 22.734 54.766v88.375zm369.75 456c-0.027344-68.184 19.781-134.91 57.008-192.04 37.227-57.125 90.262-102.19 152.65-129.71 62.387-27.516 131.43-36.289 198.72-25.258l61.625-48.625c3.8711-3.1016 7.4297-6.5781 10.625-10.375h-831.62c3.2383 3.8008 6.8398 7.2734 10.75 10.375l259.5 204.75c6.4453 5.3164 10.125 13.273 10 21.625v173.25c0 20.531 8.1484 40.227 22.656 54.758 14.504 14.531 34.188 22.711 54.719 22.742h2.5c-6.2148-26.719-9.2773-54.07-9.125-81.5zm653.25 0c0 59.656-17.691 117.97-50.832 167.57-33.145 49.602-80.25 88.262-135.37 111.09-55.113 22.832-115.76 28.805-174.27 17.164-58.512-11.637-112.25-40.363-154.44-82.547s-70.91-95.926-82.547-154.44c-11.641-58.508-5.668-119.16 17.164-174.27 22.828-55.117 61.488-102.22 111.09-135.37 49.602-33.141 107.92-50.832 167.57-50.832 79.965 0.097656 156.63 31.91 213.17 88.453s88.355 133.21 88.453 213.17zm-353.38-68.375c0-21.25 23.75-39.25 51.75-39.25s51.75 18 51.75 39.25c0 8.9297 4.7656 17.184 12.5 21.652 7.7344 4.4648 17.266 4.4648 25 0 7.7344-4.4688 12.5-12.723 12.5-21.652 0-41.625-32.75-76.625-76.75-86.375v-20.75c0-8.9297-4.7656-17.184-12.5-21.652-7.7344-4.4648-17.266-4.4648-25 0-7.7344 4.4688-12.5 12.723-12.5 21.652v20.75c-44 9.75-76.75 44.75-76.75 86.375 0 49.25 45.75 89.25 101.75 89.25 28.125 0 51.75 18 51.75 39.25s-24.125 39.25-51.75 39.25-51.75-18-51.75-39.25c0-8.9297-4.7656-17.184-12.5-21.652-7.7344-4.4648-17.266-4.4648-25 0-7.7344 4.4688-12.5 12.723-12.5 21.652 0 41.625 32.75 76.625 76.75 86.375v28.875c0 8.9297 4.7656 17.184 12.5 21.652 7.7344 4.4648 17.266 4.4648 25 0 7.7344-4.4688 12.5-12.723 12.5-21.652v-28.875c44-9.75 76.75-44.75 76.75-86.375 0-49.25-45.625-89.25-101.75-89.25-28 0-51.75-18.5-51.75-39.25z"
      />
    </SvgIcon>
  );
}
