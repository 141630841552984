import {
  Create,
  Datagrid,
  List,
  Resource,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { CrudPermissions } from "../auth/crud-premissions";
import { TopHelpInfoText } from "../help/top-help-info-text";
import { AdminPanelSettings } from "@mui/icons-material";

export const GoodDataOrgConfigResource = (perms: CrudPermissions) => (
  <Resource
    name="gooddata-org-config"
    key="gooddata-org-config"
    options={{
      label: "GoodData Org Config",
    }}
    list={GoodDataOrgConfigList}
    edit={undefined} // No need to ever edit this.
    create={perms.create ? GoodDataOrgConfigCreate : undefined}
    icon={AdminPanelSettings}
  />
);

export const GoodDataOrgConfigList = () => (
  <div>
    <TopHelpInfoText text="[AU Admin Only] What workspace ID in GoodData is used for this organization.  There is only one entry per org and there should never be a need to edit this." />

    <List>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label="ID of this Config" />
        <TextField source="workspaceId" label="Workspace ID" />
      </Datagrid>
    </List>
  </div>
);

export const GoodDataOrgConfigCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <TextInput
        source="workspaceId"
        label="Workspace ID"
        helperText="GoodData workspace ID associated with this org."
      />
    </SimpleForm>
  </Create>
);
