import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { provideCreateRoot } from "@gooddata/sdk-ui-ext";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://b8943a9bc35e7328fbe4d116f21b2362@o291228.ingest.us.sentry.io/4507698354520064",
  integrations: [],
  environment: process.env.REACT_APP_PUI_ENV,
});

provideCreateRoot(ReactDOM.createRoot);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
