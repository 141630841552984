import { OrgDto, UserDto } from "@arrowsup/platform-dtos";
import { AxiosInstance } from "axios";
import { useContext, useMemo } from "react";
import { makeApi } from "../api/use-api";
import { usePlatformUiEnv } from "../env/use-platform-ui-env";
import { AccessTokenContext } from "./access-token-context";

export class ImpersonationService {
  constructor(private readonly api: AxiosInstance) {}

  /**
   * @returns The orgs that the current user can impersonate.
   */
  readonly getOrgs = async (): Promise<OrgDto[]> => {
    const resp = await this.api.get<OrgDto[]>("/api/organizations");
    return resp.data;
  };

  /**
   * @returns Users in the specified org that the current user can impersonate.
   */
  readonly getUsers = async (org: OrgDto): Promise<UserDto[]> => {
    const resp = await this.api.get<UserDto[]>(`/api/users/org/${org.id}`);
    return resp.data;
  };
}

export const useImpersonationService = (): ImpersonationService => {
  const env = usePlatformUiEnv();
  const accessToken = useContext(AccessTokenContext).accessToken;

  return useMemo(() => {
    // Make our own API that will not use any impersonated users.
    const api = makeApi(env, accessToken);
    return new ImpersonationService(api);
  }, [env, accessToken]);
};
