import {
  BooleanField,
  BooleanInput,
  BulkDeleteButton,
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  Resource,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { TopHelpInfoText } from "../help/top-help-info-text";
import WebhookIcon from "@mui/icons-material/Webhook";
import { CrudPermissions } from "../auth/crud-premissions";
import { WebhookResource as WebhookResourceDto } from "@arrowsup/platform-dtos";
import { omit } from "lodash";

export const WebhookResource = (crudPermissions: CrudPermissions) => {
  return (
    <Resource
      name="webhook"
      key="webhook"
      options={{
        label: "Webhooks",
      }}
      icon={WebhookIcon}
      list={WebhookList(crudPermissions)}
      create={crudPermissions.create ? WebhookCreate : undefined}
      edit={crudPermissions.update ? WebhookEdit : undefined}
    />
  );
};

const WebhookUrlInput: React.FC = () => (
  <TextInput
    source="webhookUrl"
    label="Webhook URL"
    helperText="Where to POST the data."
    fullWidth={true}
    sx={{ pb: 2 }}
  />
);

const NotesInput: React.FC = () => (
  <TextInput
    source="notes"
    label="Notes"
    helperText="Notes about this webhook, for posterity."
    fullWidth={true}
    sx={{ pb: 2 }}
  />
);

const EnabledInput: React.FC = () => (
  <BooleanInput
    source="enabled"
    label="Enabled"
    helperText="If this trigger is active."
  />
);

const WebhookEdit: React.FC = () => {
  return (
    <Edit
      redirect="list"
      transform={(d: WebhookResourceDto) => omit(d, ["createdAt", "updatedAt"])}
    >
      <SimpleForm>
        <WebhookUrlInput />
        <NotesInput />
        <EnabledInput />
      </SimpleForm>
    </Edit>
  );
};

const WebhookList = (crudPermissions: CrudPermissions) => {
  return (
    <div>
      <TopHelpInfoText text="Send new leads to other platforms, via webhooks, to trigger custom workflows (e.g. in Zapier)." />
      <List exporter={false}>
        <Datagrid
          rowClick={crudPermissions.update ? "edit" : false}
          bulkActionButtons={
            crudPermissions.delete ? <BulkDeleteButton /> : false
          }
        >
          <TextField source="webhookUrl" label="Webhook URL" />
          <BooleanField source="enabled" label="Enabled" />
          <TextField source="notes" label="Notes" />
          <DateField source="createdAt" label="Created" />
          <DateField source="updatedAt" label="Updated" />
        </Datagrid>
      </List>
    </div>
  );
};

const WebhookCreate: React.FC = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <WebhookUrlInput />
        <NotesInput />
        <EnabledInput />
      </SimpleForm>
    </Create>
  );
};
