import {
  AuResourceName,
  AuPolicy,
  checkAuthorization,
} from "@arrowsup/platform-dtos";

/**
 * convenience type for CRUD permissions
 */
export type CrudPermissions = {
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
};

/**
 * For a given auResourceName and set of permissions,
 * look up which CRUD actions are allowed
 * @param auResourceName The resource name
 * @param permissions the users permissions
 * @returns which CRUD operations are allowed
 */
export const getPermissions = (
  auResourceName: AuResourceName,
  permissions: AuPolicy[]
): CrudPermissions => {
  return {
    create: checkAuthorization(auResourceName, permissions, "create"),
    read: checkAuthorization(auResourceName, permissions, "read"),
    update: checkAuthorization(auResourceName, permissions, "update"),
    delete: checkAuthorization(auResourceName, permissions, "delete"),
  };
};

/**
 * Get a record of all AuResourceNames to their CrudPermissions for the
 * current user
 * @param permissions The user's permissions
 * @returns a Record from AuResourceName to CrudPermissions
 */
export const getAllPermissions = (
  permissions: AuPolicy[]
): Record<AuResourceName, CrudPermissions> => {
  return {
    examples: getPermissions("examples", permissions),
    users: getPermissions("users", permissions),
    "web-view-filter": getPermissions("web-view-filter", permissions),
    dashboard: getPermissions("dashboard", permissions),
    "gooddata-org-config": getPermissions("gooddata-org-config", permissions),
    webhook: getPermissions("webhook", permissions),
    "google-ads-click-conversion-config": getPermissions(
      "google-ads-click-conversion-config",
      permissions
    ),
    conversion: getPermissions("conversion", permissions),
    "landing-page": getPermissions("landing-page", permissions),
    "conversion-filter": getPermissions("conversion-filter", permissions),
  };
};
