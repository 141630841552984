import { assert } from "superstruct";
import { requiredEnv } from "../env/required-env";
import { AuthConfig } from "./auth-config.type";
import { PlatformUiEnv } from "../platform-ui-envs.type";

export interface AuthConfigService {
  config: () => AuthConfig;
}

class AuthConfigServiceImpl implements AuthConfigService {
  config = (): AuthConfig => {
    const env = requiredEnv("REACT_APP_PUI_ENV");

    assert(env, PlatformUiEnv);

    return {
      auth0ClientId: requiredEnv("REACT_APP_AUTH0_CLIENT_ID"),
      auth0Domain: requiredEnv("REACT_APP_AUTH0_DOMAIN"),
      env,
    };
  };
}

export const authConfigServiceFactory = (): AuthConfigService =>
  new AuthConfigServiceImpl();

class AuthConfigServiceMock implements AuthConfigService {
  config = (): AuthConfig => ({
    auth0ClientId: "mock-auth0-id",
    auth0Domain: "mock-auth0-domain",
    env: "dev",
  });
}

export const mockAuth0ConfigServiceFactory = (): AuthConfigService =>
  new AuthConfigServiceMock();
