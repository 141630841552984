import { useContext, useEffect, useState } from "react";
import { useImpersonationService } from "../../logic/auth/impersonation.service";
import { OrgDto } from "@arrowsup/platform-dtos";
import { Loading } from "../feedback/loading";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { UserContext } from "../../logic/auth/user-context";

export const OrgPicker: React.FC = () => {
  const userContext = useContext(UserContext);
  const impersonationService = useImpersonationService();
  const [orgs, setOrgs] = useState<OrgDto[] | undefined>(undefined);

  useEffect(() => {
    impersonationService.getOrgs().then(setOrgs).catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (orgs === undefined) {
    return <Loading />;
  }

  if (userContext.user === undefined) {
    throw new Error("Users should be loaded by now");
  }

  const idPrefix = "impersonate-org-";
  const inputLabelId = idPrefix + "label";

  return (
    <FormControl size="small" fullWidth={true}>
      <InputLabel id={inputLabelId} color="secondary">
        Org
      </InputLabel>
      <Select
        labelId={inputLabelId}
        id={idPrefix + "select"}
        value={userContext.impersonatedOrg?.id ?? userContext.user.org.id}
        label="Org"
        onChange={(e) => {
          const org = orgs.find((org) => org.id === e.target.value);
          if (org === undefined) {
            throw new Error("Org not found");
          }
          userContext.setImpersonatedOrg(org);
        }}
      >
        {orgs.map((org) => (
          <MenuItem key={org.id} value={org.id}>
            {org.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
