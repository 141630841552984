import { OrgAppConfig } from "@arrowsup/platform-dtos";
import { AxiosInstance } from "axios";

/**
 * This is the service that gets the app config, which contains
 * which oauth providers are configured for the user's org.
 */
export interface AppConfigService {
  config: () => Promise<OrgAppConfig>;
}

class AppConfigServiceImpl implements AppConfigService {
  constructor(private readonly api: AxiosInstance) {}

  readonly config = async (): Promise<OrgAppConfig> => {
    const resp = await this.api.get<OrgAppConfig>(`/api/app-config`);
    return resp.data;
  };
}

export const useAppConfigService: (api: AxiosInstance) => AppConfigService = (
  api: AxiosInstance
) => {
  return new AppConfigServiceImpl(api);
};
